import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import { DialogResponses } from '~/stores/useDialogStore';
import { useUserContext } from '~/stores/useUserContext';
import { useSecureSessionPost } from '../dataFetching/genericFetchers';
import type {
  Result,
  PostBody,
} from '~/server/api/[site]/user/account/collectiveInvoice.post';

export default function useCollectiveInvoiceDialog() {
  const dialogStore = useDialogStore();
  const userContext = useUserContext();

  const site = useSiteIdent();
  type openOptions = { activate: boolean };
  return {
    open: async (options: openOptions) => {
      return dialogStore.openDialog(DialogIdent.COLLECTIVE_INVOICE, options);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(
        DialogIdent.COLLECTIVE_INVOICE,
      );
    },
    close: (data?: any) => {
      dialogStore.closeDialog(data ?? DialogResponses.SUCCESS);
    },
    cancel: () => {
      dialogStore.closeDialog(DialogResponses.CANCEL);
    },
    async changeCollectiveInvoice(nv: boolean) {
      const postData: PostBody = {
        bank: userContext.maskedBankData.bankName,
        maskedIban: userContext.maskedBankData.iban,
        maskedBic: userContext.maskedBankData.bic,
        collectiveInvoice: nv,
      };
      const result = await useSecureSessionPost<Result>(
        `/api/${site}/user/account/collectiveInvoice`,
        postData,
      );
      if (result) {
        await userContext.loadAccountData(true);
      }
    },
  };
}
